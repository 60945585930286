import { Base } from './Base.model';

export class WeldManagerUser extends Base {
  constructor() {
    super();
  }
  public userId: string;
  public firstName: string;
  public lastName: string;
  public cai: string;
  public emailId: string;
  public refineryId: number;
}
