import { Base } from './Base.model';

export class UserRole extends Base {
  constructor() {
    super();
  }

  public userRoleId: number;
  public roleId: number;
  public roleName: string;
  public userId: string;
  public firstName: string;
  public lastName: string;
  public userName: string;
  public cai: string;
  public emailId: string;
  public refineryId: number;
  public refineryCode: string;
  public refineryName: string;
}
