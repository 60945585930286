import { Base } from './Base.model';

export class QAType extends Base {
  constructor() {
    super();
  }

  public qaTypeID: number;
  public qaTypeName: string;
}
