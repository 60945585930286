import { Base } from './Base.model';

export class WeldLogWeldProcess extends Base {
  constructor() {
    super();
  }

  public weldLogWeldProcessId: number;
  public weldLogId: number;
  public weldProcessId: number;
  public weldProcessName: string;
}
