import { Base } from './Base.model';

export class Schedule extends Base {
  constructor() {
    super();
  }

  public scheduleID: number;
  public scheduleName: string;
  public sortOrder: number;
}
