import { Base } from './Base.model';

export class ContractorIdentification extends Base {
  constructor() {
    super();
  }

  public contractorIdentificationId: number;
  public welderId: string;
  public firstName: string;
  public lastName: string;
  public contractCompanyName: string;
  public photo: any;
  public refineryId: number;
}
