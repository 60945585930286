import { Base } from './Base.model';

export class WeldTypeIdentifier extends Base {
  constructor() {
    super();
  }

  public weldTypeIdentifierId: number;
  public weldTypeIdentifierCode: string;
  public weldTypeIdentifierName: string;
}
