import { Base } from './Base.model';

export class Permission extends Base {
  constructor() {
    super();
  }

  public permissionId: number;
  public permissionName: string;
}
