import { Base } from './Base.model';

export class JointType extends Base {
  constructor() {
    super();
  }

  public jointTypeId: number;
  public jointTypeName: string;
}
