export class WelderCertification {
  public welderid: string;
  public refineryId: number;
  public weldername: string;
  public stampNumber: string;
  public status: string;
  public welderweldprocesses: IWelderWeldProcess[];
}

export interface IWelderWeldProcess {
  welderid: string;
  stampNumber: string;
  weldProcessId: number;
  weldprocessname: string;
  refineryId: number;
  datewelded: string;
  colorcode: number;
}
