import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StampAllocation } from '../models';
import { BaseApiService } from '../shared/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class StampAllocationApiService extends BaseApiService<StampAllocation> {
  //#region 'Angular Life Cycle'
  constructor(protected _http: HttpClient) {
    super(_http, 'stampallocation');
  }
  //#endregion

  //#region 'Get'
  public GetStampAllocationsAsync(): Observable<StampAllocation[]> {
    return this.getAll('/GetStampAllocationsAsync');
  }

  public GetStampAllocationsAsyncById(stampallocationid: number): Observable<StampAllocation> {
    const options = { stampallocationid };
    return this.getById(options, '/GetStampAllocationsAsync');
  }
  //#endregion

  //#region 'Create'
  //#endregion

  //#region 'Update'
  //#endregion

  //#region 'Delete'
  //#endregion

  //#region 'Other'
  //#endregion
}
