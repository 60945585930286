import { Base } from './Base.model';

export class WelderStamp extends Base {
  constructor() {
    super();
  }

  public welderId: string;
  public firstName: string;
  public lastName: string;
  public welderName: string;
  public emailId: string;
  public isContractWelder: boolean;
  public stampNumber: string;
  public stampAllocationId: number;
  public status: string;
  public refineryId: number;
  public allocationStartDate: string;
  public allocationEndDate?: string;
}
