import { Base } from './Base.model';

export class Welder extends Base {
  constructor() {
    super();
  }

  public welderId: string;
  public firstName: string;
  public lastName: string;
  public cai: string;
  public emailId: string;
  public refineryId: number;
  public isContractWelder: boolean;
}
