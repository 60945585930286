import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { WeldLog } from 'src/app/core/models';
import { DropdownService } from 'src/app/data/services';

@Component({
  selector: 'app-qa-type',
  templateUrl: './qa-type.component.html',
  styleUrls: ['./qa-type.component.scss']
})
export class QaTypeComponent implements OnInit {
  //#region 'Variables'
  @Input() public action: number; // 0 = New, 1 = Edit, 2 = View
  @Input() public currentLog: WeldLog = new WeldLog();

  public formDisabled = false;
  public qaForm = new UntypedFormGroup({
    qaTypesAndLabels: new UntypedFormArray([])
  });

  //#endregion

  constructor(public _srvDropDown: DropdownService) {}

  ngOnInit(): void {
    this.setUpMode();
    this.resetFields();
    if (this.action === 0) {
      this.setupQADates('Default load', 0);
    }
  }

  ngOnDestroy(): void {
    this.resetFields();
  }

  private setUpMode(): void {
    if (this.action === 0) {
      this.newLog();
    } else if (this.action === 1) {
      this.editLog();
    } else if (this.action === 2) {
      this.viewLog();
    }
  }

  private resetFields() {
    this._srvDropDown.qaTypeLabelList = [];
    this._srvDropDown.splitQATypeLabelValues = [];
    this._srvDropDown.splitIndividualValues = [];
    this._srvDropDown.hasDefectTypeList = [];
  }

  public newLog() {
    this.addQATypeAndLabelRow();
    this.enableAllQATypes();
    this.enableForm();
    this.qaForm.controls['qaTypesAndLabels']['controls'][0]['controls']['qaTypeID'].setValue(5);
    this._srvDropDown.GetQATypeLabels(5, 0);
    this.qaForm.controls['qaTypesAndLabels']['controls'][0]['controls']['qaTypeLabelID'].setValue(6);
  }

  public editLog() {
    this.setupQaRows();
    this.enableDisableQATypes();
    this.enableForm();
  }

  public viewLog() {
    this.setupQaRows();
    this.enableDisableQATypes();
    this.disableForm();
  }

  public getQATypeAndLabelRows(): UntypedFormArray {
    return this.qaForm.get('qaTypesAndLabels') as UntypedFormArray;
  }

  public getQATypeAndLabelRowByIndex(index: number): UntypedFormGroup {
    return this.getQATypeAndLabelRows().at(index) as UntypedFormGroup;
  }

  public getQADatesByIndex(index: number): UntypedFormArray {
    return this.getQATypeAndLabelRowByIndex(index).controls['qaDate'] as UntypedFormArray;
  }

  public enableDisableQATypes() {
    // dynamically enable or disable QA Types based on if they are already selected (so we can't have duplicates)
    for (const dt of this._srvDropDown.qaType.items) {
      for (let j = 0; j < this.getQATypeAndLabelRows().length; j++) {
        if (dt.id === this.getQATypeAndLabelRows().at(j).value['qaTypeID']) {
          dt.disabled = true;
          break;
        }
        dt.disabled = false;
      }
    }
  }

  public removeQATypeAndLabelRow(index: number) {
    this.getQATypeAndLabelRows().removeAt(index);
    this.enableDisableQATypes();
  }
  public removeHasDefectTypeRow(index: number) {
    this._srvDropDown.hasDefectTypeList.splice(index, 1);
  }

  public clearQATypeLabel(index: number) {
    this.qaForm.controls['qaTypesAndLabels']['controls'][index]['controls']['qaTypeLabelID'].setValue(null);
  }

  public enableAllQATypes() {
    for (let item of this._srvDropDown.qaType.items) {
      item.disabled = false;
    }
  }

  private createDateGroup(dt: any): UntypedFormGroup {
    let date = new UntypedFormControl();
    if (dt.qaDate != null) {
      date.setValue(dt.qaDate.split('T')[0]);
    }
    return new UntypedFormGroup({
      date: date
    });
  }

  private async setupQaRows() {
    this.getQATypeAndLabelRows().clear();

    if (this.currentLog.weldLogQAs.length === 0) {
      // stampProcesses is empty, add item and bomb out
      this.addQATypeAndLabelRow();
      return;
    }

    //setup QATypeAndLabelRows based what we received from backend, else add empty row
    for (const dt of this.currentLog.weldLogQAs) {
      let index = this.getQATypeAndLabelRows()
        .getRawValue()
        .map(e => e.qaTypeID)
        .indexOf(dt.qaTypeID);

      //existing item, add date to list
      if (index !== -1) {
        this.getQADatesByIndex(index).push(this.createDateGroup(dt));
        continue;
      }

      //new items, add new row
      const PCS = new UntypedFormGroup({
        qaTypeID: new UntypedFormControl(dt.qaTypeID),
        qaTypeLabelID: new UntypedFormControl(dt.qaTypeLabelID),
        qaDate: new UntypedFormArray([]),
        defectTypeID: new UntypedFormControl(dt.defectTypeID),
        ndeRequestURL: new UntypedFormControl(dt.ndeRequestURL)
      });
      (PCS.controls['qaDate'] as UntypedFormArray).push(this.createDateGroup(dt));
      this.getQATypeAndLabelRows().push(PCS);
    }

    for (let i = 0; i < this.getQATypeAndLabelRows().length; i++) {
      let row = this.getQATypeAndLabelRowByIndex(i);
      let qaTypeID = row.controls['qaTypeID'].value;

      await this._srvDropDown.GetQATypeLabels(qaTypeID, i).then(res => {
        let calendarList = this._srvDropDown.qaTypeLabelList[i].items;
        let element = calendarList.find(x => x.id == row.controls['qaTypeLabelID'].value);
        this._srvDropDown.getSplitValues(element, i);
      });
    }
  }

  public addQATypeAndLabelRow() {
    let temp = new UntypedFormGroup({
      qaTypeID: new UntypedFormControl(),
      qaTypeLabelID: new UntypedFormControl(),
      qaDate: new UntypedFormArray([]),
      defectTypeID: new UntypedFormControl()
    });

    this.getQATypeAndLabelRows().push(temp);
  }

  public setupQADates(selectedLabel: any, index: number) {
    this.getQATypeAndLabelRowByIndex(index).controls['qaDate'] = new UntypedFormArray([]);
    if (selectedLabel === 'Default load') {
      this._srvDropDown.splitQATypeLabelValues.splice(0, 1, ['Completion']);
    } else {
      this._srvDropDown.getSplitValues(selectedLabel, index);
    }

    for (let i = 0; i < this._srvDropDown.splitQATypeLabelValues[index].length; i++) {
      let control = new UntypedFormControl();
      let temp = new UntypedFormGroup({
        date: control
      });

      if (this.getQADatesByIndex(index).length > 0) {
        this.getQADatesByIndex(index).insert(index, temp);
      } else {
        this.getQADatesByIndex(index).push(temp);
      }
    }
  }

  public getDefectTypes(selectedLabel: any, index: number) {
    this._srvDropDown.GetDefectType();
  }

  public showDefectType(selectedLabel: any, index: number): boolean {
    if (
      selectedLabel?.includes('Completion') &&
      this._srvDropDown.hasDefectTypeList[index]?.length > 0 &&
      this._srvDropDown.hasDefectTypeList[index][0]
    ) {
      return true;
    }
    return false;
  }

  private disableForm() {
    this.formDisabled = true;
    for (let control of this.qaForm.controls['qaTypesAndLabels']['controls']) {
      control.disable();
    }
  }

  private enableForm() {
    this.formDisabled = false;
    for (let control of this.qaForm.controls['qaTypesAndLabels']['controls']) {
      control.enable();
    }
  }

  public openNDELink(url: string): void {
    if (url) {
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  }

  public getNDETypeURL(index: number): string {
    const row = this.getQATypeAndLabelRowByIndex(index);
    if (index > 0) {
      const selectedQATypeID = row.controls['qaTypeID'].value;
      const selectedQATypeLabelID = row.controls['qaTypeLabelID'].value;
      const urlArray = this.currentLog.weldLogQAs;
      if (urlArray !== undefined) {
        let url = urlArray.filter(x => x.qaTypeID === selectedQATypeID && x.qaTypeLabelID === selectedQATypeLabelID);
        let ndeURL = '';
        if (url.length > 0) {
          url.forEach(x => {
            if (x.ndeRequestURL !== null) {
              ndeURL = x.ndeRequestURL;
            }
          });
        }
        return ndeURL;
      } else return '';
    } else {
      return '';
    }
  }
}
