import { Base } from './Base.model';

export class QATypeLabel extends Base {
  constructor() {
    super();
  }

  public qaTypeLabelID: number;
  public labelNames: string;
  public noofLabels: number;
  public hasDefectType: boolean;
}
