import { Base } from './Base.model';

export class WallThickness extends Base {
  constructor() {
    super();
  }

  public thicknessId: number;
  public sizeId: number;
  public sizeName: string;
  public scheduleId: number;
  public scheduleName: string;
  public dn: number;
  public od: number;
  public thickness: number;
}
