import { Base } from './Base.model';

export class StampAllocation extends Base {
  constructor() {
    super();
  }

  public stampAllocationId: number;
  public welderId: string;
  public refineryId: number;
  public stampNumber: string;
  public allocationStartDate: string;
  public allocationEndDate?: string;
}
