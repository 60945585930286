import { Base } from './Base.model';

export class MaterialForm extends Base {
  constructor() {
    super();
  }

  public materialFormId: number;
  public materialFormName: string;
}
