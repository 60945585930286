import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RTAcceptedRejected } from '../models';
import { BaseApiService } from '../shared/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class RTAcceptedRejectedApiService extends BaseApiService<RTAcceptedRejected> {
  //#region 'Angular Life Cycle'
  constructor(protected _http: HttpClient) {
    super(_http, 'rtacceptedrejected');
  }
  //#endregion

  //#region 'Get'
  public GetRTAcceptedRejectedsAsync(): Observable<RTAcceptedRejected[]> {
    return this.getAll('/GetRTAcceptedRejectedsAsync');
  }

  public GetRTAcceptedRejectedsAsyncById(rtacceptedrejectedid: number): Observable<RTAcceptedRejected> {
    const options = { rtacceptedrejectedid };
    return this.getById(options, '/GetRTAcceptedRejectedsAsync');
  }

  public GetRTAcceptedRejectedsByRefineryAsync(refineryid: number): Observable<RTAcceptedRejected> {
    const options = { refineryid };
    return this.getById(options, '/GetRTAcceptedRejectedsByRefineryAsync');
  }
  //#endregion

  //#region 'Create'
  //#endregion

  //#region 'Update'
  //#endregion

  //#region 'Delete'
  //#endregion

  //#region 'Other'
  //#endregion
}
