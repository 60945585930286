import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { JointType } from '../models';
import { BaseApiService } from '../shared/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class JointTypeApiService extends BaseApiService<JointType> {
  //#region 'Angular Life Cycle'
  constructor(protected _http: HttpClient) {
    super(_http, 'jointtype');
  }
  //#endregion

  //#region 'Get'
  public GetJointTypesAsync(): Observable<JointType[]> {
    return this.getAll('/GetJointTypesAsync');
  }

  public GetJointTypesAsyncById(jointtypeid: number): Observable<JointType> {
    const options = { jointtypeid };
    return this.getById(options, '/GetJointTypesAsync');
  }
  //#endregion

  //#region 'Create'
  //#endregion

  //#region 'Update'
  //#endregion

  //#region 'Delete'
  //#endregion

  //#region 'Other'
  //#endregion
}
