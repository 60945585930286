import { Base } from './Base.model';

export class RTAcceptedRejected extends Base {
  constructor() {
    super();
  }

  public rtacceptedRejectedId: number;
  public rtacceptedRejectedName: string;
  public refineryId: number;
}
