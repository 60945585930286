import { Base } from './Base.model';

export class RolePermission extends Base {
  constructor() {
    super();
  }
  public rolePermissionId: number;
  public roleId: number;
  public roleName: string;
  public permissionId: number;
  public permissionName: string;
}
