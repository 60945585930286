import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WeldStatsApiService, WelderStatsApiService, WeldManagerUserApiService } from '../../core/api';
import { WeldStats, WeldStatsParams, WelderStats, WelderStatsParams } from '../../core/models';
import { UserInfoService } from './user-info.service';
import { DropdownService } from './dropdown.service';

@Injectable()
export class ReportingService {
  public refineryId: number;
  public userId: string;
  constructor(
    private weldStatsApiServ: WeldStatsApiService,
    private welderStatsApiServ: WelderStatsApiService,
    private readonly _srvWeldRole: WeldManagerUserApiService,
    private readonly _srvUserInfo: UserInfoService,
    private readonly _dropdownService: DropdownService
  ) {}

  //#region 'Load'
  public loadRefineries(): Observable<any> {
    return this._srvWeldRole.GetUserRolesByUserAsync(this._srvUserInfo.userId);
  }
  //#endregion

  public setparams(): void {
    const USER = JSON.parse(localStorage.getItem('user'));
    if (USER) {
      this.userId = USER.userId;
      this.refineryId = USER.refineryId;
    }
  }

  //#region 'Get'
  public GetWeldStats(params: WeldStatsParams): Promise<WeldStats> {
    this.setparams();
    let tempParams = new WeldStatsParams();
    tempParams.weldtodate = `${params.weldtodate}T00:00:00.000Z`;
    tempParams.weldfromdate = `${params.weldfromdate}T00:00:00.000Z`;
    tempParams.divisionid = params.divisionid ? params.divisionid : 0;
    tempParams.groupid = params.groupid ? params.groupid : 0;
    tempParams.unitid = params.unitid ? params.unitid : 0;
    tempParams.refineryid = Number(this.refineryId);
    return new Promise((resolve, reject) => {
      try {
        this.weldStatsApiServ.GetWeldStatsAsync(tempParams).subscribe(res => {
          res.weldStatsByJointType.sort((a, b) => (a.jointTypeName < b.jointTypeName ? -1 : 1));
          res.weldStatsByQAType = res.weldStatsByQAType
            .filter(x => x.qaTypeName !== null)
            .sort((a, b) => (a.qaTypeName < b.qaTypeName ? -1 : 1));
          resolve(res);
        });
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public GetWelderStats(params: WelderStatsParams): Promise<WelderStats[]> {
    this.setparams();
    let tempParams = new WelderStatsParams();
    tempParams.todate = `${params.todate}T00:00:00.000Z`;
    tempParams.fromdate = `${params.fromdate}T00:00:00.000Z`;
    tempParams.refineryid = Number(this.refineryId);
    let tempFullName = this._dropdownService.stampAllocation.items.find(
      x => Number(x.id) == Number(params.fullName)
    )?.name;
    tempParams.fullName = tempFullName?.replace(/ *\([^)]*\) */g, ''); //regex removes the stamp number from the fullname

    return new Promise((resolve, reject) => {
      try {
        this.welderStatsApiServ.GetWelderStatsAsync(tempParams).subscribe(res => {
          resolve(res);
        });
      } catch (err) {
        reject(Error(err));
      }
    });
  }
  //#endregion
}
