import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Permission } from '../models';
import { BaseApiService } from '../shared/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionApiService extends BaseApiService<Permission> {
  //#region 'Angular Life Cycle'
  constructor(protected _http: HttpClient) {
    super(_http, 'permission');
  }
  //#endregion

  //#region 'Get'
  public GetPermissionsAsync(): Observable<Permission[]> {
    return this.getAll('/GetPermissionsAsync');
  }

  public GetPermissionsAsyncById(permissionid: number): Observable<Permission> {
    const options = { permissionid };
    return this.getById(options, '/GetPermissionsAsync');
  }
  //#endregion

  //#region 'Create'
  //#endregion

  //#region 'Update'
  //#endregion

  //#region 'Delete'
  //#endregion

  //#region 'Other'
  //#endregion
}
